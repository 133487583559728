import { useQuery } from "@apollo/client";
import { Spacing } from "@hark-tech/components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ScreenWrapper } from "../../app/ScreenWrapper";
import { Typography } from "../../components";
import { Button, ButtonVariant } from "../../components/Button";
import { Card } from "../../components/Card";
import { Icon, IconType } from "../../components/icon";
import { MeDocument } from "../../generated/graphql/graphql";
import {
  HarkLogo,
  HelgelandLogo,
  ThemeType,
  TinnLogo,
  useTheme,
} from "../../theme";
import { NoHarkUnitSvg } from "./NoHarkUnitSvg";

export const NoHarkUnit = (): JSX.Element => {
  const { properties, orderUrl } = useTheme();

  const [orderUrlToUse, setOrderUrlToUse] = useState(orderUrl ?? "");

  const { loading, error, data } = useQuery(MeDocument, {
    onCompleted: (data) => {
      try {
        let externalUserId = "";

        if (!data?.me) {
          console.error("Error: `me` data is missing");
          return;
        }

        if (
          !data.me.verifiedIdentities ||
          data.me.verifiedIdentities.length === 0
        ) {
          console.error("Error: No verified identities found");
          return;
        }

        data.me.verifiedIdentities?.map((identity) => {
          if (identity.__typename === "ExternalUserIdentity") {
            externalUserId = identity.externalId;
          }
        });

        if (properties.type === ThemeType.HELGELAND) {
          setOrderUrlToUse(`${orderUrl}${externalUserId}`);
        }
      } catch (err) {
        console.error("Unexpected error processing user data:", err);
      }
    },
    onError: (err) => {
      console.error("GraphQL Query Error:", err);
    },
  });

  useEffect(() => {
    setOrderUrlToUse(orderUrl ?? "");
  }, [orderUrl]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  const logo = () => {
    switch (properties.type) {
      case ThemeType.DARK:
      case ThemeType.LIGHT:
        return HarkLogo();
      case ThemeType.TINN:
        return TinnLogo();
      case ThemeType.HELGELAND:
      default:
        return HelgelandLogo();
    }
  };

  return (
    <ScreenWrapper childSpacing={Spacing.MEDIUM} queryLoading={loading}>
      <Typography
        emphasized
        text="For å aktivere denne tjenesten trenger du en sanntidsmåler. Ta kontakt med oss for å komme i gang."
      />

      {orderUrl && (
        <Button
          buttonVariant={ButtonVariant.PRIMARY}
          onClick={() => (window.location.href = orderUrlToUse)}
          text="Bestill her"
          iconRight={<Icon type={IconType.EXTERNAL_LINK} centerVertically />}
          iconLeft={logo()}
        />
      )}

      <Card>
        <Typography emphasized text="Sanntidsdata" />
        {CardRow("Se ditt strømforbruk live")}
        {CardRow("Oversikt over hjemmets aktivitetsnivå")}
        {CardRow("Undersøk hvilke laster som trekker mye")}
        {CardRow("Spar penger på nettleien")}
      </Card>

      <Card>
        <Typography emphasized text="Varsling" />
        {CardRow("Få beskjed når din grense nærmer seg")}
        {CardRow("Gjør besparende grep i tide")}
        {CardRow("Spar penger på nettleien")}
      </Card>

      <NoHarkUnitSvg />
    </ScreenWrapper>
  );
};

const CardRow = (text: string) => {
  return (
    <StyledCardRow>
      <Icon type={IconType.CHECK} centerVertically />
      <Typography text={text} centerVertically />
    </StyledCardRow>
  );
};

const StyledCardRow = styled.div`
  display: flex;
  height: 20px;
  gap: 8px;
  margin-top: 8px;
  align-items: center;
`;
