import { Col, Spacing } from "@hark-tech/components";
import { BrowserRouter } from "react-router-dom";
import { AppConfigProvider } from "../app-config/AppConfigProvider";
import { AuthProvider } from "../auth/AuthProvider";
import { GraphqlProvider } from "../graphql/GraphqlProvider";
import { AlertProvider, DeviceProvider } from "../providers";
import { ThemeProvider } from "../theme/ThemeProvider";
import { Routes } from "./Routes";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://131658e585155c66041d4d8b504afdc3@o4508796188098560.ingest.de.sentry.io/4508796191047760",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
});

function App(): JSX.Element {
  return (
    <Col style={{ alignItems: "center", flexGrow: 1 }}>
      <Col
        style={{
          height: "100%",
          padding: `${Spacing.SMALL} ${Spacing.MEDIUM}`,
          width: "clamp(256px, 100%, 100%)",
        }}
      >
        <BrowserRouter>
          <ThemeProvider>
            <AppConfigProvider>
              <AuthProvider>
                <GraphqlProvider>
                  <DeviceProvider>
                    <AlertProvider>
                      <Routes />
                    </AlertProvider>
                  </DeviceProvider>
                </GraphqlProvider>
              </AuthProvider>
            </AppConfigProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Col>
    </Col>
  );
}

export default App;
